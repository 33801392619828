import { template as template_8607789c600d4cc48613129891436909 } from "@ember/template-compiler";
const WelcomeHeader = template_8607789c600d4cc48613129891436909(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

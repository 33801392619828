import { template as template_1e10391e08df4aaabdd383f1c9d9ad57 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1e10391e08df4aaabdd383f1c9d9ad57(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
